import * as React from 'react';
import css from './PaymentPortalPage.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Footer from 'styleguide/components/Footer/Footer';
import TitleContainer from '../Account/TitleContainer/TitleContainer';
import { Status } from 'libs/utils/api/types';
import Button from 'styleguide/components/Button/Button';
import Span from 'styleguide/components/Span/Span';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';
import { createPayment } from 'api/payments';
import { getOrderFromToken } from 'api/orders/orders';
import { Order, PaymentMethod } from 'api/orders/types';
import { addressSchema, creditCardSchema } from 'utils/schema';
import { Form, Formik, FormikErrors } from 'formik';
import { Address, emptyAddress, emptyCreditCard } from 'bundles/App/pages/Account/Addresses/types';
import Loader from 'styleguide/components/Loader/Loader';
import { formikStatus, updateFormikStatus } from 'libs/utils/updateFormik';
import queryString from 'qs';
import { boolean, number, object } from 'yup';
import {
  ExistingCreditCardForm,
  useDefaultWalletId,
} from 'app/styleguide/components/Formik/ExistingCreditCardForm/ExistingCreditCardForm';
import UserContext from 'app/contexts/UserContextContainer/UserContext';
import { getCreditCardProcessorByNumber } from 'app/utils/cc';
import AddressBook from 'app/styleguide/components/Formik/AddressBook/AddressBook';
import { formatMoney } from 'utils/money';
import AppContext from 'app/contexts/AppContext/AppContext';

interface Props {
  order?: Order;
  paymentMethod: PaymentMethod;
}

type PaymentPortalFormType = {
  useExistingCard: boolean;
  creditCardAttributes?: typeof emptyCreditCard;
  walletPaymentSourceId: number;
  addressAttributes: typeof emptyAddress;
};

const PaymentPortalPage = ({ ...props }: Props) => {
  const appContext = React.useContext(AppContext);
  const { currentUser } = React.useContext(UserContext);
  const defaultWalletId = useDefaultWalletId();
  const [order, setOrder] = React.useState<Order>(props.order);
  const [newBillAddress, setNewBillAddress] = React.useState(!order?.billingAddress);
  const [redirect, setRedirect] = React.useState(false);
  const { orderNumber } = useParams<{ orderNumber: string }>();
  const location = useLocation();
  const history = useHistory();
  const authToken: string = queryString.parse(location.search, { ignoreQueryPrefix: true })
    .auth_token as string;

  const buildAddresses = React.useCallback(() => {
    const newAddresses = currentUser ? [...currentUser.addresses] : [];
    if (order?.billingAddress && newAddresses.every(adr => adr.id !== order?.billingAddress?.id)) {
      newAddresses.push(order?.billingAddress);
    }
    return newAddresses;
  }, [order?.billingAddress, currentUser]);
  const [addresses, setAddresses] = React.useState<Address[]>([]);

  React.useEffect(() => {
    if (order) {
      setAddresses(buildAddresses());
    }
  }, [buildAddresses, order]);

  const getOrderHandler = () => {
    getOrderFromToken(orderNumber, authToken).then(res => {
      if (res.status === Status.Ok) {
        setOrder(res.payload);
        setNewBillAddress(!res.payload.billingAddress);
      }
      if (res.status === Status.AuthError) {
        history.push('/sign-in');
      }
    });
  };

  React.useEffect(() => {
    if (!props.order) {
      getOrderHandler();
    }
  }, [props.order]);

  const onSubmit = (
    values: PaymentPortalFormType,
    setStatus: (status?: formikStatus) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    setErrors: (errors: FormikErrors<PaymentPortalFormType>) => void,
  ) => {
    createPayment(orderNumber, authToken, {
      payment: {
        amount: order.outstandingBalance,
        paymentMethodId: props.paymentMethod.id.toString(),
      },
      useExistingCard: values.useExistingCard,
      ...(values.useExistingCard
        ? {
            walletPaymentSourceId: values.walletPaymentSourceId,
          }
        : {
            paymentSource: {
              [props.paymentMethod.id]: {
                ...values.creditCardAttributes,
                ccType: getCreditCardProcessorByNumber(values.creditCardAttributes.number),
              },
            },
          }),
    }).then(res => {
      updateFormikStatus(res, setStatus, setSubmitting, setErrors);
      if (res.status === Status.Ok) {
        setOrder(res.payload);
        setRedirect(true);
      }
    });
  };

  if (redirect) {
    return <Redirect to={`/orders/${orderNumber}/payments/success`} />;
  }

  const paymentPortalSchemaCreditCard = object().shape({
    useExistingCard: boolean().required(),
    creditCardAttributes: object().when('useExistingCard', {
      is: value => value,
      then: () => object(),
      otherwise: () => creditCardSchema,
    }),
    walletPaymentSourceId: number().when('useExistingCard', {
      is: value => value,
      then: schema => schema.required(),
      otherwise: schema => schema.nullable(),
    }),
    addressAttributes: addressSchema(appContext.store.countries),
  });

  return order ? (
    <>
      <TitleContainer data-cy="paymentPortalTitle" title="Payment Portal" />
      <Grid.Container>
        <Formik
          initialValues={{
            creditCardAttributes: emptyCreditCard,
            addressAttributes: order?.billingAddress || emptyAddress,
            useExistingCard: !!currentUser && currentUser.walletPaymentSources.length > 0,
            walletPaymentSourceId: defaultWalletId || null,
            addressId: order?.billingAddress?.id || null,
          }}
          onSubmit={(values, { setStatus, setSubmitting, setErrors }) =>
            onSubmit(values, setStatus, setSubmitting, setErrors)
          }
          validationSchema={paymentPortalSchemaCreditCard}
        >
          {formikProps => (
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Col className={css.paymentSuccessTitle}>
                    <Span>
                      Order # {orderNumber} - Outstanding Balance:&nbsp;
                      <Span className={css.bold}>{formatMoney(order.outstandingBalance)}</Span>
                    </Span>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col lg={6} md={12}>
                    <ExistingCreditCardForm
                      title="Payment Method"
                      formikProps={formikProps}
                      creditCardFormPrefix="creditCardAttributes"
                    />
                  </Grid.Col>
                  <Grid.Col lg={6} md={12}>
                    <AddressBook
                      addressType=""
                      heading="Billing Address"
                      addresses={addresses}
                      newAddress={newBillAddress}
                      setNewAddress={setNewBillAddress}
                      titleOnTheLeft
                      addNewAddressToBookCheckBox={!!currentUser}
                      addressFormProps={{
                        className: '!mt-0',
                      }}
                      addBottomBorder={false}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Button
                    dataCy="portalPayNowBtn"
                    className={css.submitButton}
                    type="submit"
                    color="orange"
                    disabled={order.outstandingBalance <= 0.0 || formikProps.isSubmitting}
                  >
                    Pay Now
                  </Button>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid.Container>
      <Footer />
    </>
  ) : (
    <Loader size="xl" />
  );
};

export default PaymentPortalPage;
